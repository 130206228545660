import React from 'react';
import SophieBluel from '../../img/sophie-bluel.webp';
import NinaCarducci from '../../img/NinaCarducci.webp';
import eddy from '../../img/eddy2.webp';
import Atelier from '../../img/atelier.webp';
import Smartup from '../../img/Smartup.webp';
//import Slide  from '../../img/Slide.webp';
import TontonTajwid from '../../img/TontonTajwid.jpeg';

import './GalleryCardProject.css'


const GalleryCardProject = () => {

      const gallery = [
        {
          avatar: eddy,
          name: "Eddy Cherif - Praticien en médecine chinoise",
          desc: "Création d'un site vitrine en deux pages adaptés à toutes tailles d'écrans, présentant l'activité du praticien.",
          link: "https://eddy-cherif-mtc.vercel.app/",
          filter: 'react',
      },
      {
        avatar: NinaCarducci,
        name: "Nina Carducci - Portfolio Photographe",
        desc: "Amélioration des performances globale du site, réduction du temps de chargement, et renforcement de son référencement sur les moteurs de recherche (SEO).",
        link: "https://mtfl1.github.io/NinaCarducci/",
        filter: 'seo',
    },
    {
      avatar: Atelier,
      name: "Association L'Antre Connaissance",
      desc: "Création d'un site sur-mesure adaptés à toutes tailles d'écrans, présentant l'association et ses différents activitées.",
      link: "https://lantre-connaissance.com/",
      filter: 'react',
    },
    {
      avatar: SophieBluel,
      name: "Sophie Bluel - Portfolio Architecte",
      desc: "Création de la page de présentation des travaux de l'architecte, avec options de filtrage par catégorie. Développement de la page de connexion sécurisée de l'administrateur. Conception d'une modale permettant d'ajouter ou de supprimer des projets.",
      link: "https://sophie-bluel-architect.vercel.app/",
      filter: 'javascript',
      },
        {
          avatar: TontonTajwid,
          name: "Tonton Tajwid - Cours particuliers",
          desc: "Réalisation d'une page de présentation d'un professeur et ses différents cours",
          link: "https://tontontajwid.com/",
          filter: 'html/css',
      },
        {
            avatar: Smartup,
            name: "Smartup - application mobile pour les créateurs de start-up",
            desc: "Réalisation d'une application mobile, qui permet au créateur de start-up de définir leur idée de manière professionel à l'aide d'un questionnaire qui fait office d'entonnoir et d'une IA.",
            link: "",
            filter: 'html/css',
        },
  
    ]
     
  

      return (
        <section className="py-14" id="projets">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8">
            <div className="max-w-xl md:mx-auto">
              <h2 className="text-#f4f3f3 text-2xl font-extrabold sm:text-4xl text-center">
                Nos dernières réalisations
              </h2>
            </div>
             {/*
            <div className="mt-6 flex justify-center gap-4">
              <button className={filter === 'all' ? 'active' : ''} onClick={() => handleFilter('all')}>Tous</button>
              <button className={filter === 'html/css' ? 'active' : ''} onClick={() => handleFilter('html/css')}>HTML/CSS</button>
              <button className={filter === 'javascript' ? 'active' : ''} onClick={() => handleFilter('javascript')}>JavaScript</button>
              <button className={filter === 'react' ? 'active' : ''} onClick={() => handleFilter('react')}>React</button>
              <button className={filter === 'seo' ? 'active' : ''} onClick={() => handleFilter('seo')}>SEO</button>
            </div>*/}
            <div className="mt-12">
              <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
                {gallery.map((item, idx) => (
                  <li key={idx}>
                    <a
                      href={item.link} 
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:cursor-pointer"
                    >
                      <div className="w-full sm:h-52 md:h-56">
                        <img
                          src={item.avatar}
                          className="w-full h-full object-fill object-center rounded-xl shadow-card border-2"
                          alt=""
                        />
                      </div>
                      <div className="mt-4">
                        <h4 className="text-lg text-[--slate-800] font-semibold">{item.name}</h4>
                        <p className="text-#f4f3f3 mt-2  ">{item.desc}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )
    }      
    export default GalleryCardProject;